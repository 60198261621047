section {
  position: relative;
  height: 120vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 80%;
  background-repeat: no-repeat;
  padding-top: 150px;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
}

h1 {
  color: white;
  text-shadow: 1px 1px 3px black;
  z-index: 1;
  font-size: 3em;
  font-weight: 400;
}
