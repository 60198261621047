body {
  font-family: 'Kanit', sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  background-color: rgb(252, 251, 248);
}

.container {
  position: relative;
  overflow: hidden;
  /* padding-top: 40px; */
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(252, 251, 248);
}

.color-container {
  position: absolute;
  z-index: 1;
}

.left-container {
  display: flex;
  height: 100vh;
  width: 50vw;
  background-color: rgba(255, 105, 180, 0.5);
}

.right-container {
  display: flex;
  height: 100vh;
  width: 50vw;
  background-color: rgba(255, 255, 255, 0.5);
}

.background-container {
  position: absolute;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center; /* Aligns horizontally */
  align-items: flex-end; /* Aligns vertically at the bottom */
  background-image: url('https://res.cloudinary.com/sheyou/image/upload/v1708711855/Subject_8e78de5e06.png'); /* Your image link */
  background-position: center bottom; /* Center the background image at the bottom */
  background-repeat: no-repeat;
  background-size: 60vh;
  z-index: 2;
}

.content-container {
  position: absolute;
  z-index: 4;
}

.text1 {
  left: 0;
  display: flex;
  height: 100vh;
  width: 50vw;
  color: white;
  font-size: 6vw;
  justify-content: center;
  align-items: center;
}

.text3 {
  position: absolute;
  display: flex;
  height: 100vh;
  width: 100vw;
  font-size: 6vw;
  z-index: 3;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
}

/* .text1:hover{
  border-radius: 50%; 
  text-align: center;
  background-image: radial-gradient(
    circle,
    hotpink,
    transparent
  )
} */

.text1:hover {
  transform: scale(1.2);
}

.text2 {
  right: 0;
  display: flex;
  height: 100vh;
  width: 50vw;
  color: hotpink;
  font-size: 6vw;
  justify-content: center;
  align-items: center;
}

.text2:hover {
  transform: scale(1.2);
}

/* .text2:hover{
  border-radius: 50%;
  text-align: center;
  background-image: radial-gradient(
    circle,
    white,
    transparent
  )
} */

.intro-text {
  position: absolute;
  display: flex;
  height: 35vh;
  width: 100vw;
  background-image: url('https://res.cloudinary.com/sheyou/image/upload/v1711553703/Untitled_Artwork_beecba3bd0.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  justify-content: center;
  align-items: center;
  z-index: 4;
  bottom: 12vh;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 1050px) {
  .tag-cloud-container {
    width: 90vw;
    height: 80vh;
  }
  
  .tag-cloud {
    width: 90vw;
    height: 90vh;
  }

  .tag-cloud .ui.label {
    font-size: 0.9em !important;
    padding: 6px 12px !important;
  }

  #pic4 {
    width: 60vh;
    height: 60vh;
  }

  .intro-text {
    /* width: 80vw; */
    height: 30vh;
    /* bottom: 20vh; */
  }
}



#pic1 {
  height: 80vh;
  bottom: 100px;
  z-index: 2;
}

@media (max-width: 1050px) {
  #pic1 {
    position: absolute;
    left: 100px;
    bottom: 80px;
  }
}

#pic2 {
  height: 100vh;
  position: absolute;
  left: -20px;
  z-index: 1;
}



#pic3 {
  height: 40vh;
  z-index: 1;
  position: absolute;
  left: 150px;
  top: 70px;
}

#pic4 {
  height: 75vh;
  position: absolute;
  z-index: 2;
  border-radius: 50%;
  object-fit: cover;
  object-position: top center;
  aspect-ratio: 1/1;
  width: 75vh;
  background-image: radial-gradient(
    circle 33vh at center,
    rgb(255, 23, 136),
    transparent 100%
  );
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#pic5 {
  height: 40vh;
  position: absolute;
  right: 100px;
  z-index: 3;
  bottom: 50px;
}

.image-placement {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: flex-end;
  background-color: beige;
}

/* Removing dot styles */
.dot {
  display: none;
}

#dot1, #dot2, #dot3, #dot4, #dot5, #dot6 {
  display: none;
}

/*scroll to view gallery*/

.gridBlock {
  opacity: 0;
}

/* Grid almost fully pulled from https://www.rino-pelle.com/ */
.grid-container {
  width: 100vw;
  height: 470vh;
  position: relative;
  background-image: linear-gradient(
    rgb(252, 251, 248),
    rgb(255, 23, 136),
    transparent
  );
  background-size: 60%;
}

@media only screen and (max-width: 1100px) {
  .grid-container {
    height: 450vh;
    width: 100vw;
  }
}

@media only screen and (max-width: 850px) {
  .grid-container {
    height: 410vh;
    width: 100vw;
  }
}
.grid {
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 1;
  width: 70vw;
  height: 60vw;
  margin: 0 calc(100vw / 20 * 3);
}
@media only screen and (max-width: 580px) {
  .grid {
    margin: 0;
    width: 100vw;
    height: 89.444vw;
  }
}
.gridBlock {
  background-size: cover;
  /* background-color: darkgray; */
}
.gridLayer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  will-change: transform;
  transform-origin: 44.951% 50%;
}
.gridLayer:nth-child(4) .gridBlock {
  top: 5vw;
  left: 20vw;
  width: 10vw;
  height: 10vw;
}
@media only screen and (max-width: 580px) {
  .gridLayer:nth-child(4) .gridBlock {
    top: 11.111vw;
    left: 23.889vw;
    width: 15vw;
    height: 15vw;
  }
}
.gridLayer:nth-child(2) .gridBlock {
  top: 0;
  left: 32.5vw;
  width: 15vw;
  height: 15vw;
}
@media only screen and (max-width: 580px) {
  .gridLayer:nth-child(2) .gridBlock {
    top: 0;
    left: 43.333vw;
    width: 26.389vw;
    height: 26.389vw;
  }
}
.gridLayer:nth-child(3) .gridBlock {
  left: -5vw;
  top: 20vw;
  width: 20vw;
  height: 20vw;
}
@media only screen and (max-width: 580px) {
  .gridLayer:nth-child(3) .gridBlock {
    top: 30.278vw;
    left: 0;
    width: 26.389vw;
    height: 26.389vw;
  }
}
.gridLayer:nth-child(1) .gridBlock {
  top: 17.5vw;
  left: 17.5vw;
  width: 30vw;
  height: 30vw;
}
@media only screen and (max-width: 580px) {
  .gridLayer:nth-child(1) .gridBlock {
    top: 30.278vw;
    left: 30.278vw;
    width: 39.444vw;
    height: 39.444vw;
  }
}
.gridLayer:nth-child(5) {
  z-index: 1;
}
.gridLayer:nth-child(5) .gridBlock {
  top: 5vw;
  left: 50vw;
  width: 20vw;
  height: 20vw;
}
@media only screen and (max-width: 580px) {
  .gridLayer:nth-child(5) .gridBlock {
    top: 23.889vw;
    left: 73.889vw;
    width: 15.278vw;
    height: 15.278vw;
  }
}
.gridLayer:nth-child(5) a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gridLayer:nth-child(5) svg {
  width: 1.75vw;
}
@media only screen and (max-width: 580px) {
  .gridLayer:nth-child(5) svg {
    width: 4.722vw;
  }
}
.gridLayer:nth-child(6) .gridBlock {
  left: 50vw;
  top: 27.5vw;
  width: 20vw;
  height: 20vw;
}
@media only screen and (max-width: 580px) {
  .gridLayer:nth-child(6) .gridBlock {
    top: 43.889vw;
    left: 73.889vw;
    width: 26.389vw;
    height: 26.389vw;
  }
}
.gridLayer:nth-child(7) .gridBlock {
  left: 0vw;
  top: 42.5vw;
  width: 15vw;
  height: 15vw;
}
@media only screen and (max-width: 580px) {
  .gridLayer:nth-child(7) .gridBlock {
    display: none;
  }
}
.gridLayer:nth-child(8) .gridBlock {
  bottom: -10vw;
  left: 22.5vw;
  width: 25vw;
  height: 20vw;
}
/* @media only screen and (max-width: 580px) {
  .gridLayer:nth-child(8) .gridBlock {
    display: none;
  }
} */
.gridLayer:nth-child(9) .gridBlock {
  bottom: -5vw;
  left: 50vw;
  width: 15vw;
  height: 15vw;
}
@media only screen and (max-width: 580px) {
  .gridLayer:nth-child(9) .gridBlock {
    top: 73.889vw;
    left: 58.889vw;
    width: 15.278vw;
    height: 15.278vw;
  }
}
.gridBlock {
  display: block;
  position: absolute;
}

.header-section {
  /* background: linear-gradient(to bottom, white, pink); */
  color: rgb(255, 23, 136);
  display: flex;
  font-size: 8vh;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-top: 0;
}

.content-section {
  color: white;
  display: flex;
  font-size: 8vh;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  position: absolute;
  width: 50vw;
  height: 50vh;
  z-index: 2;
  left: 30vw;
  top: 30vw;
}

.page-center {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: radial-gradient(
    circle 50vh at center,
    rgb(255, 23, 136),
    transparent 100%
  );
  /* Adjust the 'transparent 100%' to manage the gradient's fade effect */
}

.centered-text {
  font-size: 30px; /* Example font size, adjust as needed */
  color: rgb(252, 251, 248); /* Example text color, adjust as needed */
}

.centered-text:hover{
  text-decoration: underline;
}

.tag-cloud-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: transparent;
  pointer-events: none;
  overflow: hidden;
}

.tag-cloud {
  position: relative;
  width: 100%;
  height: 100%;
}

.tag-cloud a {
  transition: all 0.3s ease-out;
  font-family: 'Kanit', sans-serif;
}

.tag-cloud a:hover {
  color: rgb(255, 23, 136) !important;
  opacity: 0.8 !important;
  text-decoration: none;
  transform: scale(1.1) rotate(0deg) !important;
}

/* Media queries for different screen sizes */
@media only screen and (max-width: 1200px) {
  .tag-cloud a {
    font-size: calc(1vw + 0.5vh) !important;
  }
}

@media only screen and (max-width: 768px) {
  .tag-cloud a {
    font-size: calc(1.2vw + 0.6vh) !important;
  }
}

@media only screen and (max-width: 480px) {
  .tag-cloud a {
    font-size: calc(1.5vw + 0.8vh) !important;
  }
}

/* Disable hover effects on touch devices */
@media (hover: none) {
  .tag-cloud a:hover {
    transform: none !important;
    opacity: 0.3 !important;
  }
}


/* Media query for smaller screens */

