.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loading-icon {
    font-size: 48px; /* Adjust size as needed */
    /* animation: pulse 1s infinite alternate; */
  }
  
  /* @keyframes pulse {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.5);
    }
  } */
  